export default function ({ ...props }) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12.5013 18.9582H7.5013C2.9763 18.9582 1.04297 17.0248 1.04297 12.4998V7.49984C1.04297 2.97484 2.9763 1.0415 7.5013 1.0415H12.5013C17.0263 1.0415 18.9596 2.97484 18.9596 7.49984V12.4998C18.9596 17.0248 17.0263 18.9582 12.5013 18.9582ZM7.5013 2.2915C3.65964 2.2915 2.29297 3.65817 2.29297 7.49984V12.4998C2.29297 16.3415 3.65964 17.7082 7.5013 17.7082H12.5013C16.343 17.7082 17.7096 16.3415 17.7096 12.4998V7.49984C17.7096 3.65817 16.343 2.2915 12.5013 2.2915H7.5013Z'
        fill='#9B9DA2'
      />
      <path
        d='M18.3331 7.7085H1.69141C1.34974 7.7085 1.06641 7.42516 1.06641 7.0835C1.06641 6.74183 1.34974 6.4585 1.69141 6.4585H18.3331C18.6747 6.4585 18.9581 6.74183 18.9581 7.0835C18.9581 7.42516 18.6747 7.7085 18.3331 7.7085Z'
        fill='#9B9DA2'
      />
      <path
        d='M18.3331 13.5415H1.69141C1.34974 13.5415 1.06641 13.2582 1.06641 12.9165C1.06641 12.5748 1.34974 12.2915 1.69141 12.2915H18.3331C18.6747 12.2915 18.9581 12.5748 18.9581 12.9165C18.9581 13.2582 18.6747 13.5415 18.3331 13.5415Z'
        fill='#9B9DA2'
      />
      <path
        d='M7.08984 18.9498C6.74818 18.9498 6.46484 18.6665 6.46484 18.3248V1.6748C6.46484 1.33314 6.74818 1.0498 7.08984 1.0498C7.43151 1.0498 7.71484 1.33314 7.71484 1.6748V18.3165C7.71484 18.6665 7.43984 18.9498 7.08984 18.9498Z'
        fill='#9B9DA2'
      />
      <path
        d='M12.9258 18.9498C12.5841 18.9498 12.3008 18.6665 12.3008 18.3248V1.6748C12.3008 1.33314 12.5841 1.0498 12.9258 1.0498C13.2674 1.0498 13.5508 1.33314 13.5508 1.6748V18.3165C13.5508 18.6665 13.2758 18.9498 12.9258 18.9498Z'
        fill='#9B9DA2'
      />
    </svg>
  );
}
