import achievementsBG from '~/images/banners/achievement-bg.svg';
import achievementBanner from '~/images/banners/achievment-banner.png';
import becomeSellerBanner from '~/images/banners/become-seller-banner.png';
import bestProductCover from '~/images/banners/best-product-coverr.png';
import blogCover from '~/images/banners/blog-cover.png';
import bundleCover from '~/images/banners/bundle.png';
import courseBanner from '~/images/banners/course-banner.png';
import courseBG from '~/images/banners/course-bg.png';
import courseImage from '~/images/banners/course-img.png';
import demoCover from '~/images/banners/demo-cover.jpg';
import hero_box_Banner from '~/images/banners/hero-box.png';
import menuBanner from '~/images/banners/menu-banner.png';
import productBanner from '~/images/banners/product-banner.png';
import productCover from '~/images/banners/product-cover.png';
import sampleBannerMobile from '~/images/banners/sample-banner-mobile.png';
import sampleSlide from '~/images/banners/sample-slide.png';
import storyBanner from '~/images/banners/story-banner.png';
import ticketGuide from '~/images/banners/ticket-guid.png';
import zhaket_academy_Banner from '~/images/banners/zhaket-academy.png';
import zhaket_services_Banner from '~/images/banners/zhaket-service-banner.png';
import aboutusBG from '~/images/common/aboutBG.svg';
import affiliateBG from '~/images/common/affiliate-bg.svg';
import authBG from '~/images/common/auth-bg.svg';
import avatar from '~/images/common/avatar.png';
import becomeSellerBg from '~/images/common/become-seller-bg.svg';
import bestSellerBg from '~/images/common/best-seller.svg';
import comma from '~/images/common/camma.svg';
import cartBg from '~/images/common/cart-bg.svg';
import companyMembersBG from '~/images/common/company-members.png';
import contactUsBg from '~/images/common/contactus-background.png';
import homeScreen from '~/images/common/home_screen.png';
import heroBG from '~/images/common/home-bg.svg';
import heroBGMobile from '~/images/common/home-bg-mobile.svg';
import productDefaultCover from '~/images/common/no-image-product-cover.png';
import removeCartBG from '~/images/common/remove-cart-bg.svg';
import rotatingSliderBG from '~/images/common/rotating-slider-bg.png';
import categorySpotLightBG from '~/images/common/spotlight-bg.svg';
import zhaketLocation from '~/images/common/zhaket_location.png';
import zhaketLogoBG from '~/images/common/zhk_logo_bg.png';
import banimodeLogo from '~/images/company-logo/banimode.svg';
import digikalaLogo from '~/images/company-logo/digikala.svg';
import divarLogo from '~/images/company-logo/divar.svg';
import fidiboLogo from '~/images/company-logo/fidibo.svg';
import flightioLogo from '~/images/company-logo/flightio.svg';
import hamyarLogo from '~/images/company-logo/hamyar.svg';
import khanoumiLogo from '~/images/company-logo/khanoumi.svg';
import miareLogo from '~/images/company-logo/miare.svg';
import nobitexLogo from '~/images/company-logo/nobitex.svg';
import sibappLogo from '~/images/company-logo/sibapp.png';
import sibcheLogo from '~/images/company-logo/sibche.png';
import error403 from '~/images/errors/403.png';
import error404 from '~/images/errors/404.svg';
import error429 from '~/images/errors/429.svg';
import error500 from '~/images/errors/500.svg';
import errorBG from '~/images/errors/errorbg.svg';
import addIcon from '~/images/icons/add.svg';
import addBlue from '~/images/icons/add-blue.svg';
import addCommentIcon from '~/images/icons/add-comment-icon.svg';
import addOrange from '~/images/icons/add-orange.svg';
import addToCartIcon from '~/images/icons/add-to-cart-icon.svg';
import affiliateLabelIcon from '~/images/icons/affiliate-label.svg';
import automaticUpdate from '~/images/icons/automatic-update.svg';
import becomeSeller from '~/images/icons/become-seller.svg';
import bellIcon from '~/images/icons/bell-icon.svg';
import blackTicketIcon from '~/images/icons/black-ticket-icon.svg';
import bookmarkIcon from '~/images/icons/bookmark.svg';
import gradientCartIcon from '~/images/icons/cart-gradient.svg';
import changelogIcon from '~/images/icons/changelog.svg';
import checkIcon from '~/images/icons/check-icon.svg';
import checkbox from '~/images/icons/checkbox.svg';
import commentsTreeBranchIcon from '~/images/icons/comment-tree-branch.svg';
import commentsIcon from '~/images/icons/comments.svg';
import contactAvatar1 from '~/images/icons/contact-avatar-1.jpg';
import contactAvatar2 from '~/images/icons/contact-avatar-2.jpg';
import contactAvatar3 from '~/images/icons/contact-avatar-3.png';
import contentIcon from '~/images/icons/content-icon.svg';
import dashboardIcon from '~/images/icons/dashboard.svg';
import defaultMedalIcon from '~/images/icons/default-medal.svg';
import digitIcon from '~/images/icons/digit-icon.svg';
import discountIcon from '~/images/icons/discount.svg';
import dislikeIcon from '~/images/icons/dislike-icon.svg';
import downloadIconOrange from '~/images/icons/download-icon-orange.svg';
import downloadIconWhite from '~/images/icons/download-icon-white.svg';
import editIcon from '~/images/icons/edit.svg';
import errorIcon from '~/images/icons/error-icon.svg';
import eyeWithSquareIcon from '~/images/icons/eye_with_square.svg';
import faqIcon from '~/images/icons/faq.svg';
import fireIcon from '~/images/icons/fire.svg';
import gridViewIcon from '~/images/icons/grid-view-icon';
import guarantee from '~/images/icons/guarantee.svg';
import guard from '~/images/icons/guard.svg';
import highlightItemIcon from '~/images/icons/highlight-item.svg';
import hotProductIcon from '~/images/icons/hot-product-icon.svg';
import infoAlertIcon from '~/images/icons/info-alert-icon.svg';
import infoIcon from '~/images/icons/info-icon.svg';
import irMap from '~/images/icons/ir-map.svg';
import iranianProductsMedal from '~/images/icons/iranian-products-icon.svg';
import likeIcon from '~/images/icons/like-icon.svg';
import linkedinIcon from '~/images/icons/linkedin-logo.svg';
import locationIcon from '~/images/icons/location-icon.svg';
import loginToCommentIcon from '~/images/icons/login-to-comment.svg';
import logoutIcon from '~/images/icons/logout.svg';
import mainProductsMedal from '~/images/icons/main-products-icon.svg';
import medal from '~/images/icons/medal.svg';
import medalBasedFilter from '~/images/icons/medal-based-filter-icon.svg';
import minusIcon from '~/images/icons/minus-icon.svg';
import moneyBackGuarantee from '~/images/icons/money-back-guarantee.svg';
import orangeLoading from '~/images/icons/orange-loading.svg';
import orangeProfile from '~/images/icons/orange-profile.svg';
import pannelTicketIcon from '~/images/icons/pannel_ticket_icon.svg';
import permanentSupport from '~/images/icons/permanent-support.svg';
import phoneCallIcon from '~/images/icons/phone_call_icon.svg';
import plusIcon from '~/images/icons/plus-icon.svg';
import productCategories from '~/images/icons/product-categories-icon.svg';
import questionIcon from '~/images/icons/question-icon.svg';
import relatedProductIcon from '~/images/icons/related-product-icon.svg';
import replyIcon from '~/images/icons/reply.svg';
import rowViewIcon from '~/images/icons/row-view-icon';
import saleBackgroundIcon from '~/images/icons/sale-bg.svg';
import settingIcon from '~/images/icons/setting.svg';
import shareIcon from '~/images/icons/share-icon.svg';
import shieldIcon from '~/images/icons/shield.svg';
import shoppingCart from '~/images/icons/shopping-cart.svg';
import showLive from '~/images/icons/show-live-icon.svg';
import specialProductsMedal from '~/images/icons/special-products-icon.svg';
import gradientStarIcon from '~/images/icons/star-gradient.svg';
import successIcon from '~/images/icons/success-icon.svg';
import supportIcon from '~/images/icons/support-icon.svg';
import tagIcon from '~/images/icons/tag-icon.svg';
import telegramIcon from '~/images/icons/telegram-logo.svg';
import termBrowser from '~/images/icons/term-browser.svg';
import termEmail from '~/images/icons/term-email.svg';
import termPass from '~/images/icons/term-pass.png';
import ticketIcon from '~/images/icons/ticket-icon.svg';
import twitterIcon from '~/images/icons/twitter-logo.svg';
import unavailableProducts from '~/images/icons/unavailable-products-icon.svg';
import updater from '~/images/icons/updater.svg';
import vendorIcon from '~/images/icons/vendor-icon.svg';
import verified from '~/images/icons/verified.svg';
import warningIcon from '~/images/icons/warning-icon.svg';
import whatsappIcon from '~/images/icons/whatsapp-logo.svg';
import whiteLoading from '~/images/icons/white-loading.svg';
import yoastIcon from '~/images/icons/yoast-icon.svg';
import darkLogo from '~/images/icons/zhaket-dark-logo.svg';
import zhaketLicencedProductsMedal from '~/images/icons/zhaket-licenced-icon.svg';
import lightLogo from '~/images/icons/zhaket-light-logo.svg';
import orangeLogo from '~/images/icons/zhaket-orange-logo.svg';
import eNamad from '~/images/logo/e-namad.svg';
import zhkBrownSmLogo from '~/images/logo/zhk-brown-sm.svg';
import zhkDarkLogo from '~/images/logo/zhk-dark-logo.svg';
import zhkSmGrayLogo from '~/images/logo/zhk-gray-sm.svg';
import zhkGreenSmLogo from '~/images/logo/zhk-green-sm.svg';
import zhkLightLogo from '~/images/logo/zhk-light-logo.svg';
import zhaketOrangeLogo from '~/images/logo/zhk-orange-logo.svg';
import zhaketFavIcon from '~/images/logo/zhk-white-sm.svg';

const assets = {
  icons: {
    zhaketFavIcon,
    automaticUpdate,
    permanentSupport,
    moneyBackGuarantee,
    becomeSeller,
    vendorIcon,
    yoastIcon,
    digitIcon,
    shoppingCart,
    medal,
    zhkGreenSmLogo,
    zhkBrownSmLogo,
    verified,
    irMap,
    guarantee,
    guard,
    updater,
    contactAvatar1,
    contactAvatar2,
    contactAvatar3,
    eNamad,
    showLive,
    productCategories,
    unavailableProducts,
    medalBasedFilter,
    mainProductsMedal,
    iranianProductsMedal,
    specialProductsMedal,
    zhaketLicencedProductsMedal,
    checkbox,
    questionIcon,
    fireIcon,
    zhkDarkLogo,
    termPass,
    termEmail,
    termBrowser,
    gridViewIcon,
    rowViewIcon,
    discountIcon,
    saleBackgroundIcon,
    changelogIcon,
    commentsIcon,
    addCommentIcon,
    addIcon,
    replyIcon,
    commentsTreeBranchIcon,
    highlightItemIcon,
    tagIcon,
    supportIcon,
    ticketIcon,
    blackTicketIcon,
    addToCartIcon,
    bookmarkIcon,
    contentIcon,
    faqIcon,
    affiliateLabelIcon,
    relatedProductIcon,
    loginToCommentIcon,
    whiteLoading,
    orangeLoading,
    dashboardIcon,
    logoutIcon,
    settingIcon,
    addBlue,
    addOrange,
    orangeProfile,
    zhkLightLogo,
    shareIcon,
    likeIcon,
    dislikeIcon,
    defaultMedalIcon,
    infoIcon,
    gradientStarIcon,
    gradientCartIcon,
    zhkSmGrayLogo,
    hotProductIcon,
    pannelTicketIcon,
    zhaketOrangeLogo,
    phoneCallIcon,
    errorIcon,
    warningIcon,
    successIcon,
    infoAlertIcon,
    locationIcon,
    eyeWithSquareIcon,
    checkIcon,
    editIcon,
    plusIcon,
    minusIcon,
    bellIcon,
    linkedinIcon,
    whatsappIcon,
    twitterIcon,
    telegramIcon,
    lightLogo,
    orangeLogo,
    darkLogo,
    downloadIconOrange,
    downloadIconWhite,
    shieldIcon,
    comapniesLogo: {
      banimodeLogo,
      digikalaLogo,
      divarLogo,
      fidiboLogo,
      flightioLogo,
      hamyarLogo,
      khanoumiLogo,
      miareLogo,
      nobitexLogo,
      sibappLogo,
      sibcheLogo,
    },
  },
  images: {
    storyBanner,
    courseBanner,
    bundleCover,
    productCover,
    sampleSlide,
    sampleBannerMobile,
    productBanner,
    avatar,
    becomeSellerBanner,
    bestProductCover,
    blogCover,
    courseImage,
    menuBanner,
    demoCover,
    ticketGuide,
    productDefaultCover,
    zhaketLocation,
    hero_box_Banner,
    zhaket_academy_Banner,
    zhaket_services_Banner,
    achievementBanner,
    comma,
    homeScreen,
    error404,
    error500,
    error403,
    error429,
  },
  backgrounds: {
    heroBG,
    heroBGMobile,
    achievementsBG,
    bestSellerBg,
    becomeSellerBg,
    rotatingSliderBG,
    categorySpotLightBG,
    courseBG,
    authBG,
    affiliateBG,
    cartBg,
    zhaketLogoBG,
    companyMembersBG,
    removeCartBG,
    contactUsBg,
    aboutusBG,
    errorBG,
  },
};
export default assets;
