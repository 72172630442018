import { createElement } from 'react';

import cn from '@/lib/clsxm';

import { COLOR_ENUM } from '@/components/@base/@helpers/types';
import Props, { HEADING_TYPE } from '@/components/@base/typography/heading/type';

export default function Heading({
  type = HEADING_TYPE.H3,
  color = COLOR_ENUM.TEXT,
  className,
  children,
  ...props
}: Props) {
  const colorClassNames = cn(
    color === COLOR_ENUM.TEXT && 'text-i-typography',
    color === COLOR_ENUM.INVERT && 'text-i-typography-invert',
  );
  const typeClassNames = cn(
    type === HEADING_TYPE.H1 && 'text-lg font-bold',
    type === HEADING_TYPE.H2 && 'text-lg font-bold',
    type === HEADING_TYPE.H3 && 'text-lg font-bold',
    type == HEADING_TYPE.H4 && 'text-lg font-bold',
    type === HEADING_TYPE.H5 && 'text-base font-bold',
  );

  return createElement(
    type,
    {
      className: cn('text-2xl font-bold', typeClassNames, colorClassNames, className),
      ...props,
    },
    children,
  );
}
