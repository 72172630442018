import { ReactNode } from 'react';

export default interface Base {
  children?: ReactNode;
  className?: string;
  center?: boolean;
}

export enum SIZE_ENUM {
  XXS = 'xxs',
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = '2xl',
}

export enum COLOR_ENUM {
  TEXT = 'text',
  INVERT = 'invert',
  SECONDARY = 'secondary',
  PRIMARY = 'primary',
  LIGHT_GRAY = 'light-gray',
  SUCCESS = 'success',
  ERROR = 'error',
  WARN = 'warn',
  PURPLE = 'purple',
  WHITE = 'white',
}

export enum USER_TYPE {
  VENDOR = 'vendor',
  CUSTOMER = 'customer',
  ADMIN = 'admin',
}
