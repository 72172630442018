export default function ({ ...props }) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12.5013 18.9582H7.5013C2.9763 18.9582 1.04297 17.0248 1.04297 12.4998V7.49984C1.04297 2.97484 2.9763 1.0415 7.5013 1.0415H12.5013C17.0263 1.0415 18.9596 2.97484 18.9596 7.49984V12.4998C18.9596 17.0248 17.0263 18.9582 12.5013 18.9582ZM7.5013 2.2915C3.65964 2.2915 2.29297 3.65817 2.29297 7.49984V12.4998C2.29297 16.3415 3.65964 17.7082 7.5013 17.7082H12.5013C16.343 17.7082 17.7096 16.3415 17.7096 12.4998V7.49984C17.7096 3.65817 16.343 2.2915 12.5013 2.2915H7.5013Z'
        fill='#FF9606'
      />
      <path
        d='M17.4087 10.8332H2.59389C2.08772 10.8332 1.66797 10.4554 1.66797 9.99984C1.66797 9.54428 2.08772 9.1665 2.59389 9.1665H17.4087C17.9149 9.1665 18.3346 9.54428 18.3346 9.99984C18.3346 10.4554 17.9149 10.8332 17.4087 10.8332Z'
        fill='#FF9606'
      />
    </svg>
  );
}
